@import '../../styles';

.list {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5em;
  padding: 1.5em 0 4em;

  &_npt {
    padding-top: 0;
  }

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    gap: .75em .5em;
    padding-top: 1em;
  }

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
    gap: 1em .75em;
  }
}

.list_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  color: var(--app-color);
  background-color: var(--card-bg);
  border: var(--border-tertiary);
  border-radius: 0.25em;
  font-family: 'Lato Regular';
  z-index: 1;
}

.card_img_holder {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; 
}

.card_img {
  width: 100%;
}

.card_content {
  width: 100%;
  padding: .5em;

  &_pb {
    padding-bottom: 1em;
  }
}

.card_id {
  display: block;
  font-size: 1.25em;
  margin-top: .5em;
}

.card_id_with_discord_icon {
  display: inline-block;
  font-size: 1.25em;
  margin-top: .5em;
  &:after { 
    background:transparent url('../../assets/svgs/discord-verified.svg') no-repeat; 
    background-position: center;
    content: '';
    width: 24px;
    height: 24px;
    background-color: #E1E3E7;
    border-radius: 50%;
    margin-left: .5em;
    display: inline-block;
    position: relative;
    top: 4px;
  }
}

.card_attibutes {
  font-size: 0.875em;
  display: flex;
  align-items: center;
}

.card_favorite {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: relative;
  margin-left: 2em;
}

.card_attr_row {
  display: flex;
  width: 100%;
  line-height: 2em;
}

.card_label {
  color: var(--label-color);
}

.card_value {
  padding-left: 1em;
}

.card_price {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em .75em;
  border-top: var(--border-tertiary);
}

.card_price_label {
  color: var(--label-color);
}

.card_price_value {
  font-family: 'Barlow Bold';
  word-break: break-word;
  padding-left: 0.5em;
}

.stake_time {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 .75em 0.5em;
  line-height: 1.75em;
  font-weight: 600;;
}

.stake_icon {
  margin-right: 0.5em;

  path {
    fill: #FEC492;
  }
}

.favorite_count {
  font-size: 0.875em;
  margin-left: 0.5em;
  line-height: 2em;
}
