@import '../../styles';

.container {
  text-align: center;
}

.bar {
  position: relative;
  height: 7em;
  display: flex;
  justify-content: center;
  background-color: var(--strip-bar-bg);

  @include mobile {
    height: 4em;
  }

  @include tablet {
    height: 5.5em;
  }
}

.bar_icon {
  position: absolute;
  bottom: -3em;
  width: 18em;

  @include mobile {
    width: 12em;
    bottom: -2em;
  }

  @include tablet {
    width: 15em;
    bottom: -2.5em;
  }
}

.title {
  font-size: 2.5em;
  font-family: 'FredokaOne';
  text-align: center;
  letter-spacing: -0.02em;
  margin: 2.5em 0 2em;

  @include mobile {
    font-size: 1.75em;
  }

  @include tablet {
    font-size: 2.25em;
  }
}

.content {
  max-width: 60em;
  margin: auto;

  @include mobile {
    padding: 0 1em;
  }

  @include tablet {
    padding: 0 2em;
  }
}

.sub1 {
  padding-left: 2em;

  @include mobile {
    padding-left: 0em;
  }

  @include tablet {
    padding-left: 1em;
  }
}

.sub2 {
  padding-left: 4em;

  @include mobile {
    padding-left: 0em;
  }

  @include tablet {
    padding-left: 2em;
  }
}

.remark {
  padding-left: 1em;

  @include mobile {
    padding-left: 0.5em;
  }
}

.metamask {
  display: flex;
}

.setup {
  flex-direction: column;
  align-items: center;
  padding-left: 1em;
  margin: 1em 0;

  &_mnt {
    display: none;
  }

  &_dk {
    display: flex;
  }

  img:first-child {
    width: 13em;
  }

  img:last-child {
    width: 10em;
  }

  @include mobile {
    &_mnt {
      display: flex;
    }

    &_dk {
      display: none;
    }
  }

  @include tablet {
    &_mnt {
      display: flex;
    }

    &_dk {
      display: none;
    }
  }
}

.article {
  display: flex;

  &_mark {
    padding-right: 1em;
    margin: 0.375em 0;
    border-left: .2em solid var(--app-color);
  }
}

.bottom_coin {
  width: 18em;
  margin: 3em auto 5em;

  @include mobile {
    width: 12em;
  }

  @include tablet {
    width: 15em;
  }
}