@import '../../styles';

.tabs {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 1em;
  white-space: nowrap;
  font-size: 0.875em;
  font-family: 'Lato Regular';

  @include mobile {
    display: none;
  }
}

.dropdown {
  width: 100%;
  min-width: 11em;

  @include mobile {
    min-width: auto;
  }
}