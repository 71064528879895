@import '../../styles';

.container {
  position: relative;
  width: 11em;

  @include mobile {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-family: 'Lato Regular';
  padding: 0 0.75em;
  background-color: var(--dropdown-bg);
  border-radius: .25em;
  border: var(--border-tertiary);
  border-width: 0.0875em;
  height: 2.875em;
  font-size: .875em;

  &_filtered {
    background-color: var(--small-button-bg);
  }
}

.header_text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  @include mobile {
    white-space: pre-wrap;
    word-break: break-word;
    text-overflow: initial;
  }
}

.dropdown_icon {
  width: 0.875em;
  min-width: 0.875em;
  fill: var(--svg-fill-tertiary);
  margin-left: .75em;
}

.content {
  position: absolute;
  top: 98%;
  left: 0em;
  right: 0em;
  background-color: var(--dropdown-bg);
  border: var(--border-tertiary);
  border-radius: 0 0 .25em .25em;
  z-index: $z-index-dropdown;
  display: none;

  &_show {
    display: initial;
  }
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .75em;
  border-bottom: var(--border-tertiary);
}

.input {
  display: flex;
  border: var(--border-tertiary);
  border-width: 0.1em;
  border-radius: .25em;
  font-size: 0.625em;
  font-family: 'Lato Regular';
  padding: .5em .5em;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--input-color-primary);
}

.line {
  width: .5em;
  min-width: .5em;
  height: 0;
  border-bottom: var(--border-tertiary);
  margin: 0 .25em;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .875em;
  padding: .75em;
}

.button {
  width: 100%;
  font-size: 0.625em;
  font-family: 'Lato Regular';
  height: 2.5em;
  border-radius: 1.5em;
  cursor: pointer;
  color: var(--app-color);
  
  &_clear {
    background-color: transparent;
    border: var(--border-primary);
    border-width: 0.1em;
  }

  &_apply {
    border: none;
    background-color: var(--small-button-bg);
  }
}