@import '../../styles';

.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--data-box-bg);
  padding: .875em 1.5em;
  border-radius: .25em;
  font-size: .875em;
  text-align: center;

  @include mobile {
    padding: .75em .5em;
  }
}

.label {
  color: var(--label-color);
  font-family: 'Lato Regular';
  margin: .25em 0;
}

.value {
  font-family: 'Barlow Bold';
  margin: .25em 0;
}
