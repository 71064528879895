@import '../../styles';

.component {
  background: var(--banner-bg);
  padding: 1em 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: $z-index-bar;

  @include mobile {
    margin-top: 4em;
  }
}

.content {
  display: flex;
  white-space: nowrap;
  animation: scrollInfinitely 20s linear 0.5s infinite;
}

@keyframes scrollInfinitely {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.alert_item {
  margin-left: 5em;
  display: flex;
  align-items: center;
  min-width: 20em;
}

.alert_img {
  max-width: 0.875em;
  margin-right: 0.5em;
}

.alert_txt {
  color: var(--banner-color);
  font-size: 0.875em;
}
