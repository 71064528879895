@import '../../styles';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
    align-items: initial;
  }
}

.header {
  display: flex;
  align-items: center;
  font-size: 0.875em;

  @include mobile {
    justify-content: space-between;
    padding: .77em 1em;
    border: var(--border-tertiary);
    border-width: 0.0875em;
    background-color: var(--dropdown-bg);
    border-radius: .25em;
    cursor: pointer;
    width: 100%;
  }

  @include tablet {
    margin-bottom: .5em;
  }
}

.label {
  display: flex;
  flex-wrap: wrap;
}

.label_text {
  padding-right: .75em;

  @include mobile {
    padding-right: .25em;
  }
}

.filter_menu {
  display: none;

  @include mobile {
    display: block;
    width: 1em;

    path {
      fill: var(--svg-fill-tertiary);
    }
  }
}

.select_count {
  display: none;

  @include mobile {
    display: initial;
  }
}

.content {
  @include mobile {
    display: none;
    position: fixed;
    top: 4em;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--dropdown-bg);
    z-index: $z-index-layer2;
    padding: 0 1em 1em;
    overflow-y: auto;
  
    &_show {
      display: block;
    }
  }
}

.menu_header {
  display: none;

  @include mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em 0;
    background-color: var(--dropdown-bg);
    border-bottom: var(--border-tertiary);
    border-width: 0.125em;
  }

  svg {
    fill: var(--svg-fill-tertiary);
  }
}


.clear_dk {
  color: var(--button-bg-primary);
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;

  @include mobile {
    display: none;
  }
}

.clear_mb {
  @include mobile {
    color: var(--label-color);
    text-decoration: underline;
    cursor: pointer;
  }
}

.filters {
  display: grid;
  grid-template-columns: repeat(8, auto);
  gap: .5em;
  font-family: 'Lato Regular';

  @include mobile {
    display: flex;
    flex-direction: column;
    padding-top: 0.5em;
  }

  @include tablet {
    grid-template-columns: repeat(4, auto);
  }
}