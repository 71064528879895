@import '../../styles';

.container {
  background: var(--card-bg);
  box-shadow: .625em .625em 0 var(--strip-bar-bg);
  border-radius: .625em;
  padding: 2em;
  margin-bottom: 3.75em;
  text-align: left;

  @include mobile {
    padding: 1em;
    margin-bottom: 2em;
  }

  @include tablet {
    padding: 1.5em;
    margin-bottom: 3em;
  }
}

.title {
  font-size: 1.125em;
  font-family: 'FredokaOne';
  color: var(--title-color);
  margin-bottom: 1em;

  a {
    color: #B3A99C;
  }
}

.content {
  font-family: 'Lato Regular';
  padding: 0 5em;

  p {
    margin: .5em 0;
    line-height: 1.75em;
  }

  a {
    color: #B1A699;
  }

  @include mobile {
    padding: 0 1em;
  }

  @include tablet {
    padding: 0 2.5em;
  }
}