@import '../../styles';

.wrapper {
  display: block;
  position: relative;
  max-width: 11em;

  @include mobile {
    max-width: initial;
  }
}

.input {
  width: 100%;
  color: var(--input-color-primary);
  background-color: var(--input-bg-primary);
  border: var(--border-tertiary);
  border-width: 0.0875em;
  border-radius: .25em;
  box-shadow: none;
  padding: .725em 2em .725em .75em;
  font-size: .875em;
  font-family: 'Lato Regular';
}

.icon {
  position: absolute;
  right: .75em;
  top: 32%;
  bottom: 32%;
  fill: var(--input-color-primary);

  path {
    fill: var(--input-color-primary);
  }
}