@import '../../styles';

.bar_contrainer {
  position: relative;
}

.bar {
  left: 0;
  right: 0;
  height: 7em;
  display: flex;
  justify-content: center;
  background-color: var(--strip-bar-bg);
}

.bar_icon_wrap {
  width: 6.125em;
  height: 6.125em;
  background-color: white;
  position: absolute;
  bottom: -2.75em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar_icon {
  width:  5.5em;
  height: 5.5em;
  border-radius: 50%;
}

.wallet_icon {
  position: absolute;
  bottom: -2.75em;

  @include mobile {
    display: initial;
    width: 6em;
  }

  @include tablet {
    display: initial;
    width: 8em;
  }
}

.container {
  position: relative;
  max-width: 66.67%;
  margin: auto;
  padding: 4.5em 0;
  font-family: 'Lato Regular';

  @include mobile {
    padding: 4.5em 1em;
    max-width: initial;
  }

  @include tablet {
    padding: 4.5em 1.5em;
    max-width: initial;
  }
}

.connect_wallet {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'FredokaOne';
  margin-top: 1em;

  @include mobile {
    padding: 0 10%;
  }
}

.connect_button {
  margin-top: 1.5em;
  padding: .75em 1em;
  width: fit-content;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5em;
}

.address {
  font-size: 1.125em;
  font-family: 'FredokaOne';
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 1.5em;
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  // border-bottom: var(--border-tertiary);
  margin-bottom: 1em;
}

.tab {
  font-family: 'Lato Regular';
  text-align: center;
  color: var(--label-color);
  font-size: 1em;
  line-height: 1.75em;
  font-weight: bold;
  width: 7.5em;
  padding-bottom: .375em;
  cursor: pointer;

  @include mobile {
    flex: 1;
  }
}

.tab_active {
  border-bottom: var(--button-border);
  border-bottom-width: 0.1875em;
  transition: 3s opacity ease-out;
}