@import '../../styles';

.selection {
  position: relative;
  border-radius: .25em;
  border: var(--border-tertiary);
  background-color: var(--dropdown-bg);

  &_selected {
    background-color: var(--dropdown-selected-bg);
  }

  @include mobile {
    border: none;
    border-radius: 0;

    &_selected {
      background-color: transparent;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75em .5em;
  cursor: pointer;
  font-size: 0.875em;
  white-space: nowrap;

  &_selecting {
    color: #FFF;
    font-family: 'Lato Bold';
  }

  @include mobile {
    padding: 1em 0;
    border-bottom: var(--border-tertiary);
    border-width: 0.0875em;

    &_selecting {
      color: var(--app-color);
    }
  }
}

.icon_dropdown {
  width: .875em;
  height: .875em;
  margin-left: 1em;
  fill: var(--svg-fill-tertiary);

  &_hidden {
    display: none;
  }

  @include mobile {
    width: 1em;
    display: block;
  }
}

.icon_cross {
  width: .875em;
  height: .875em;
  margin-left: 1em;
  fill: #FFF;

  @include mobile {
    display: none;
  }
}

.content {
  min-width: 15em;
  position: absolute;
  top: 3.125em;
  left: -0.0625em;
  border: var(--border-tertiary);
  border-radius: .25em;
  background-color: var(--dropdown-bg);
  z-index: $z-index-dropdown;

  &::before {
    position: absolute;
    left: 1em;
    top: -1.35em;
    content: '';
    border-width: 0.65em;
    border-style: solid;
    border-color:transparent transparent var(--border-color-tertiary) transparent;
  }
  
  &::after {
    position: absolute;
    left: 1.055em;
    top: -1.2em;
    content: '';
    border-width: 0.6em;
    border-style: solid;
    border-color:transparent transparent var(--dropdown-bg) transparent;
  }

  &_right {
    left: auto;
    right: -.0625em;

    &::before {
      left: auto;
      right: 1em;
    }
    
    &::after {
      left: auto;
      right: 1.055em;
    }
  }

  @include mobile {
    position: relative;
    top: 0;
    border: none;
    border-bottom: 0.01875em solid #C3C6CF;
    border-radius: 0;
    padding: .75em 0;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

.options_title {
  display: grid;
  grid-template-columns: 4fr 1.5fr .5fr;
  align-items: center;
  padding: .5em;
  border-radius: .25em .25em 0 0;

  @include mobile {
    display: none;
  }
}

.sort_icon {
  fill: var(--svg-fill-tertiary);
  cursor: pointer;
  padding-left: .125em;

  &:active {
    fill: #C3C6CF;
  }
}

.options {
  max-height: 20em;
  border-radius: 0 0 .25em .25em;
  padding: 0 .5em;
  font-size: 1em;
  font-family: 'Barlow Regular';
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: .125em;
  }

  &::-webkit-scrollbar-track {
    background-color: #FFF; 
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--nav-bg);
  }

  @include mobile {
    padding: 0;
    max-height: none;
    font-size: .9em;
  }

  @include tablet {
    font-size: .9em;
  }
}

.optionItem {
  font-family: 'Barlow SemiBold';
}
