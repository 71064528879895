@import '../../styles';

.container {
  padding: 1.5em;
  background: var(--section-bg3);
  border-radius: 0.375em;

  @include mobile {
    padding: 1em;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 2.33em;
  background: var(--label-tab-bg);
  border-radius: 0.5em;
  padding: 0 0.5em;
  white-space: nowrap;
}

.starIcon {
  margin-right: 0.25em;
}

.spec {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5em;
  color: var(--app-color);
  text-align: right;
}

.merchProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 2em 0;

  @include mobile {
    flex-direction: column;
    gap: 1em;
    padding-bottom: 1em;
  }
}

.merch {
  display: flex;
  align-items: stretch;
  padding: 1em;
  gap: 2em;
  background: var(--card-bg);
  border: 0.0625em solid var(--border-color-quaternary);
  border-radius: 0.375em;
  max-width: 20em;
  width: 100%;
}

.merchCol {
  flex: 1;
  max-width: 8.75em;
  width: 100%;
  display: flex;

  img {
    width: 100%;
    height: auto;
  }

  &:nth-child(2) {
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5em 0;
  }
}

.merchTitle {
  display: block;
  font-weight: 600;
  line-height: 1.375em;
  padding-bottom: 0.5em;
}

.merchPrice {
  display: flex;
  font-size: 0.875em;
  line-height: 1.5em;
  font-family: 'Lato Black';
  padding-top: 0.5em;
}

.merchBundleSizes {
  display: flex;
  font-size: 0.75em;
  line-height: 1.75em;
  color: var(--label-color);
  white-space: pre-wrap;
}

.purchaseButton {
  max-width: 6.75em;
}
