@import '../../styles';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 66.67%;
  margin: auto;

  @include mobile {
    padding: 0em 1em;
    max-width: initial;
  }

  @include tablet {
    padding: 0em 1em;
    max-width: initial;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4em 0;

  @include mobile {
    margin: 2em 0 1em;
  }
}

.title {
  font-family: 'FredokaOne';
  font-size: 2em;
  letter-spacing: -0.02em;
}

.text {
  font-size: 1.125em;
  font-family: 'Lato Regular';
  margin: 1em 0;
  text-align: center;
  line-height: 1.5em;

  @include mobile {
    padding: 0 10vw;
  }
}

.index {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  gap: .875em;

  @include mobile {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}

.index_item:last-of-type {
  @include mobile {
    min-width: 13em;
    max-width: fit-content;
    grid-column-start: span 2;
    margin: auto;
  }
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_left {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  @include mobile {
    &_left {
      width: 100%;
      display: grid;
      grid-template-columns: .75fr 1fr;
    }
  }

  @include tablet {
    &_left {
      flex-wrap: wrap;
    }

    &_right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
}

.search {
  &_tl {
    display: none;
  }

  @include tablet {
    &_ntl {
      display: none;
    }
  
    &_tl {
      display: initial;
    }
  }
}

.list_filter {
  min-width: 8em;
  margin-right: .75em;

  @include tablet {
    min-width: 11em;
  }

  @include mobile {
    min-width: initial;
  }
}

.price_filter {
  &_dk {
    margin-right: .75em;
  }

  &_mb {
    display: none;
  }

  @include mobile {
    &_dk {
      display: none;
    }

    &_mb {
      flex: 1.45;
      display: initial;
      margin-left: .75em;
    }
  }

  &_hiden {
    display: none;
  }
}

.sorts {
  &_mb {
    display: none;
  }

  @include tablet {
    &_dk {
      margin-left: .75em;
    }
  }

  @include mobile {
    &_dk {
      display: none;
    }
  
    &_mb {
      margin-top: .75em;
      display: initial;
    }
  }
}

.row2 {
  @include mobile {
    &_pd {
      margin-top: .75em;
    }

    display: flex;
  }
}

.filters {
  display: initial;
  flex: 1;

  &_hiden {
    display: none;
  }
}

.divider {
  width: 100%;
  height: 0;
  outline: .05em solid #C3C6CF;
  margin: 1.5em 0;

  @include mobile {
    display: none;
  }
}

